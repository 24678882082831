import Colors from '@/utils/Colors'
import AppUUIDsService from '@/services/AppUUIDsService'
import ToastNotification from '@/components/shared/components/vue-toastification/ToastNotification.vue'
import { loadLanguageAsync } from '@/plugins/i18n/i18n'
import { mapActions, mapGetters, mapState } from 'vuex'
import link from '@/utils/links'
import ImageModal from '@/components/shared/components/ImageModal.vue'
import { Languages } from '@/components/shared/enums/Languages'
import operation from '@/utils/operations'
import { EventBus } from '@/eventBus'
import { UserStates } from '@/components/conversations/enums/UserStates'
import PushNotificationsController from '@/controllers/PushNotificationsController'
import { StatusActive } from '@/Enums/StatusActive'

// components
import Loader from '@/shared/infrastructure/ui/Loader.vue'
import KBDrawer from '@/shared/infrastructure/ui/menus/KBDrawer/KBDrawer.vue'
import KeybeSidebar from '@/shared/infrastructure/ui/layout/KeybeSidebar/KeybeSidebar.vue'
import DownloadApp from '@/shared/infrastructure/ui/layout/DownloadApp.vue'
import KeybeFloatingContainer from '@/shared/infrastructure/ui/layout/KeybeFloatingContainer/KeybeFloatingContainer.vue'
import NotificationsScreen from '@/modules/NotificationsCenter/infrastructure/ui/NotificationsScreen/NotificationsScreen.vue'
import store from '@/store'
import AdviserStatusController from '@/modules/conversations/infrastructure/controllers/AdviserStatus.controller'
import EventsController from '@/shared/infrastructure/controllers/EventsController'
// import SignInOnboardingInitialInfo from '@/modules/SignInOnboarding/infrastructure/components/SignInOnboardingInitialInfo/SignInOnboardingInitialInfo.vue'
// import SignInOnboardingConfigStart from '@/modules/SignInOnboarding/infrastructure/components/SignInOnboardingConfigStart/SignInOnboardingConfigStart.vue'
import UserInfoCompletion from '@/shared/infrastructure/UserInfoCompletion/UserInfoCompletion.vue'
import {
  EventSuscriptionGetter,
  GraphqlEventTypes,
  onUser
} from '@/shared/domain/Events/Event'
import EventsUseCases from '@/shared/application/EventUseCases'
import OnboardingUseCases from '@/modules/SignInOnboarding/application/OnboardingUseCases'
import OnboardingController from '@/modules/SignInOnboarding/infrastructure/controllers/Onboarding.controller'
import { Step } from '@/modules/Sales/steps/domain/models/Steps'
import { Card } from '@/modules/Sales/cards/domain/models/Cards'
import DragCardLostReason from '@/modules/Sales/steps/infrastructure/components/DragCardLostReason/DragCardLostReason.vue'

import Dialog from '@/components/shared/components/Dialog.vue'
import { dataToCheckUserOwner } from '@/modules/Apps/domain/AppsController'
import AppsUseCases from '@/modules/Apps/application/AppsUseCases'
import AppsController from '@/modules/Apps/infrastructure/controllers/Apps.controller'
import TeamUseCases from '@/modules/Config/TeamConfiguration/application/TeamUseCases'
import { dataToGetTermsByTeamMember } from '@/modules/Config/TeamConfiguration/domain/TeamController'
import TeamController from '@/modules/Config/TeamConfiguration/infrastructure/controllers/Team.controller'
import EventsApolloProvider from '@/modules/event/Providers/EventsApolloProvider'
import SubscriptionOnApp from '@/modules/Apps/infrastructure/graphql/subscription-on-app.graphql'
export default {
  name: 'App',
  components: {
    ImageModal,
    ToastNotification,
    Loader,
    KeybeSidebar,
    KBDrawer,
    NotificationsScreen,
    KeybeFloatingContainer,
    DownloadApp,
    // SignInOnboardingInitialInfo,
    // SignInOnboardingConfigStart,
    Dialog
  },
  data () {
    return {
      Colors,
      mini: false,
      expanded: true,
      loadingApps: false,
      selectingCompany: false,
      oneSignalUserId: null,
      refreshing: false,
      notificationCenterOpen: false,
      extradata: {},
      eventsController: new EventsController(),
      onboardingController: new OnboardingController(),
      appsController: new AppsController(),
      teamController: new TeamController(),
      UUID: AppUUIDsService,
      oneSignalRetryAttempts: 0,
      showOnboardingInitial: false,
      showOnboardingTutorial: false,
      showDragCardLostReason: false,
      showUserInfoCompletion: false,
      rerender: 0,
      draggedCard: {},
      indexDraggedCard: -1,
      toStep: {},
      fromStep: {},
      appSubscription: null,
      eventsApolloClient: EventsApolloProvider.getInstance().provider.defaultClient
    }
  },
  computed: {
    ...mapState('UserStore', [
      'isLoggedIn',
      'isLoading',
      'firstRender',
      'user',
      'changePassword',
      'userExtradata',
      'user',
      'deviceToken',
      'token'
    ]),
    ...mapState('AppStore', [
      'selectedApp',
      'showTutorial',
      'language',
      'showWebinarPopup'
    ]),
    ...mapState('UserStore', ['token', 'user', 'closedUserCompletion']),
    ...mapState('CompanyStore', ['selectedCompany']),
    ...mapGetters('AppStore', ['getSelectedApp', 'isKeybeApp']),
    ...mapGetters('UserStore', ['getV4Token', 'getDarkModeActive', 'isAgent', 'isSuperAdmin']),
    ...mapState('ConversationStore', ['instagramFixDialog']),
    appUUID (): string {
      return this.selectedApp?.uuid || ''
    },
    signin (): string {
      return link.signin
    },
    signup (): string {
      return link.signup
    },
    resetPassword (): string {
      return link.resetPassword
    },
    formPreview (): string {
      return link.formPreview
    },
    invitedWizard (): string {
      return link.invitedWizard
    },
    facebookMobile (): string {
      return link.facebookMobileConfig
    },
    instagramMobile (): string {
      return link.instagramMobileConfig
    },
    loginQuery (): any {
      return this.$route?.query?.firstLogin === 'true'
    },
    assistedSignup (): string {
      return link.assistedSignup
    },
    isFrontOfficeView (): boolean {
      return (
        this.$route.path === this.signin ||
        this.$route.path === this.signup ||
        this.$route.path === this.formPreview ||
        this.$route.path === this.resetPassword ||
        this.$route.path === this.invitedWizard ||
        this.$route.path === this.assistedSignup ||
        this.$route.path === link.firstLogin ||
        this.$route.path.includes(this.facebookMobile.toLowerCase()) ||
        this.$route.path.includes(this.instagramMobile.toLowerCase())
      )
    },
    DragCardLostResonComponent () {
      return DragCardLostReason
    },
    dialogBackground () {
      return this.$vuetify.theme.dark ? Colors.morningCoffee : Colors.white
    },
    UserInfoCompletionComponent () {
      return UserInfoCompletion
    },
    isTabletDevice: {
      get () {
        return this.$store.state.UserStore.isTabletDevice
      },
      set (value) {
        this.$store.commit('UserStore/SET_IS_TABLET_DEVICE', value)
      }
    }
  },
  // async created () {
  //   Listen for our custom event from the SW registration
  //   document.addEventListener('swUpdated', this.refreshOnUpdate, {
  //     once: true
  //   })
  //   if (this.token) {
  //     await AuthService.validateToken()
  //   }
  //   window.onfocus = async () => {
  //     if (this.token) {
  //       await AuthService.validateToken()
  //     }
  //   }
  // },
  beforeDestroy (): void {
    // remove window on focus
    EventBus.$off('openNotificationCenter--drawer')
    EventBus.$off('closeConfigOnboardingInitial--wizard', this.closeConfigurationOnboarding.bind(this))
    this.eventsController.unsubscribe()
    this.unmountEventToHandleIdle()
    window.onfocus = null
    EventBus.$off('openLostReason--sales', this.openLostReason.bind(this))
    EventBus.$off('selectLostReason--sales', this.cancelDrag.bind(this))
    this.appSubscription.unsubscribe()
  },
  async mounted () {
    this.checkDevice()
    EventBus.$on('openNotificationCenter--drawer', () => {
      this.notificationCenterOpen = true
    })
    EventBus.$on('closeConfigOnboardingInitial--wizard', this.closeConfigurationOnboarding.bind(this))
    store.dispatch('AppStore/setRedirectRoute', '')
    if (!this.getDarkModeActive) {
      if (
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches
      ) {
        this.$vuetify.theme.dark = true
      } else {
        this.$vuetify.theme.dark = false
      }
      this.$store.commit(
        'UserStore/SET_DARK_MODE',
        this.$vuetify.theme.dark ? StatusActive.ACTIVE : StatusActive.INACTIVE
      )
    } else {
      if (operation.areEqual(this.getDarkModeActive, StatusActive.ACTIVE)) {
        this.$vuetify.theme.dark = true
      } else if (
        operation.areEqual(this.getDarkModeActive, StatusActive.INACTIVE)
      ) {
        this.$vuetify.theme.dark = false
      }
    }

    if (!this.language) {
      const language = navigator.language
      let lang = language.split('-')[0]
      if (lang !== Languages.ES) {
        lang = Languages.EN
      }
      this.$store.commit('AppStore/SET_APP_LANGUAGE', lang)
    }
    loadLanguageAsync(this.language)

    this.cleanTemporalData()
    const url = window.location.hostname
    const bluebirdOldUrl = 'bluebird.keybe.ai'
    if (url === bluebirdOldUrl) {
      const baseURL = process.env.VUE_APP_BASE_KEYBE_URL
      const URL = `${baseURL}conversations?app=${this.getSelectedApp.uuid}&token=${this.getV4Token}`
      window.location.replace(URL)
    }
    if (this.selectedApp?.uuid && this.isLoggedIn) {
      this.createEventSubscription()
    }

    this.startInterval()
    this.mountEventToHandleIdle()
    this.getUserReadonly()
    // this.getOnboardingStep()

    this.$nextTick(() => {
      if (this.selectedApp) this.checkShowUserInfoCompletion()
    })
    this.checkDevice()
    this.setAppSubscription()
    EventBus.$on('openLostReason--sales', this.openLostReason.bind(this))
    EventBus.$on('selectLostReason--sales', this.cancelDrag.bind(this))
  },
  beforeCreate () {
    const path = this.$route.path

    if (path === link.login) {
      const query = this.$route.query
      this.$router.push({ path: link.signin, query, replace: true })
      return
    }

    if (path === link.register) {
      const query = this.$route.query
      this.$router.push({ path: link.signup, query, replace: true })
    }
  },
  methods: {
    ...mapActions('AppStore', [
      'getAdviserStatus',
      'setShowTutorial',
      'setWebinarPopup',
      'setIsAndroid'
    ]),
    ...mapActions('ConversationStore', [
      'setOneSignalUserId',
      'selectRoom',
      'cleanTemporalData'
    ]),
    ...mapActions('UserStore', ['logout', 'setUserExtradata']),
    ...mapActions('PipelinesStore', ['setSelectedPipeline']),
    setAppSubscription () {
      if (!this.token || !this.isLoggedIn) return
      console.log('=====================================================')
      console.log('===============START APP SUBSCRIPTION================')
      this.appSubscription = this.eventsApolloClient
        .subscribe({
          query: SubscriptionOnApp,
          variables: {
            appUuid: this.selectedApp?.uuid
          }
        })
        .subscribe((res) => {
          const app = res?.data?.onApp?.payload || {}
          if (Object.keys(app).length) {
            const parsed = JSON.parse(app)
            console.log('===============================================')
            console.log('===============APP DATA CHANGED================')
            console.log(parsed)
            const activeApp = parsed?.status === 'active'
            if (!activeApp) {
              store.commit('AppStore/SET_SELECTED_APP', parsed)
              this.$router.replace(link.payment)
            } else if (this.selectedApp.status !== 'active' && activeApp) {
              store.commit('AppStore/SET_SELECTED_APP', parsed)
              this.$router.replace(link.home)
            }
            console.log('===============================================')
          }
        })
    },
    checkDevice (): void {
      const userAgent = window?.navigator?.userAgent || ''
      if (userAgent) {
        const isAndroid: boolean = userAgent.includes('Android') || false
        this.setIsAndroid(isAndroid)
      }
    },
    checkShowUserInfoCompletion (): void {
      if (this.closedUserCompletion) return
      if (this.$route.path === link.payment) return
      const data: dataToGetTermsByTeamMember = {
        appUUID: this.selectedApp?.uuid,
        user: this.user
      }
      const acceptedTerms: boolean = TeamUseCases.areTermsAcceptedByApp(this.teamController, data)
      const isOwner: boolean = AppsUseCases.isUserOwner(this.appsController, { appUUID: this.selectedApp?.uuid, user: this.user })
      if (
        isOwner && (
          !this.selectedApp?.email ||
          !this.selectedApp?.phone ||
          this.selectedApp?.phone?.length < 5 ||
          !this.selectedApp?.photo ||
          !this.selectedApp?.timeZone ||
          !this.selectedApp?.lang ||
          !this.selectedApp?.country ||
          !this.selectedApp?.defaultCountryCode
        )
      ) {
        this.showUserInfoCompletion = true
      } else if (
        !this.isSuperAdmin && (
          !this.user.name ||
            !this.user.lastName ||
            !this.user.email ||
            !this.user.phone ||
            !this.user.address_country ||
            !this.user.address_province ||
            !this.user.address_city ||
            !this.user.address_zone ||
            !this.user.birthday ||
            !acceptedTerms ||
            !this.user.photo
        )
      ) this.showUserInfoCompletion = true
    },
    getUserReadonly (): void {
      const readData: dataToCheckUserOwner = {
        appUUID: this.selectedApp?.uuid,
        user: this.user
      }

      AppsUseCases.isUserReadonly(this.appsController, readData)
    },
    cancelDrag (): void {
      this.showDragCardLostReason = false
      this.fromStep = {}
      this.toStep = {}
      this.draggedCard = {}
      this.indexDraggedCard = -1
    },
    openLostReason (data: {
      fromStep: Step,
      toStep: Step,
      draggedCard: Card,
      draggedCardIndex: number
    }): void {
      this.fromStep = data.fromStep
      this.toStep = data.toStep
      this.draggedCard = data.draggedCard
      this.indexDraggedCard = data.draggedCardIndex
      this.showDragCardLostReason = true
    },
    async getOnboardingStep (): Promise<void> {
      const step: number = await OnboardingUseCases.getOnboardingStep(this.onboardingController)
      this.showOnboardingInitial = step === 1
      this.showOnboardingTutorial = step === 2
      this.rerender++
    },
    closeOnboarding (): void {
      this.showOnboardingInitial = false
      this.$router.replace({ query: { ...this.$route.query, firstLogin: undefined } })
      OnboardingUseCases.setOnboardingStep(this.onboardingController, 2)
      this.showOnboardingTutorial = true
      setTimeout(() => {
        this.rerender++
      }, 200)
    },
    closeConfigurationOnboarding (): void {
      this.showOnboardingTutorial = false
      EventBus.$emit('closeConfigOnboarding--wizard')
      this.rerender++
    },
    validateOneSignal () {
      if (!this.$OneSignal) {
        return
      }

      this.$OneSignal.isPushNotificationsEnabled((isEnabled) => {
        if (isEnabled) {
          this.$OneSignal.getUserId((userId) => {
            this.oneSignalUserId = userId
          })
        } else {
          this.$OneSignal.showNativePrompt()
          this.$OneSignal.on('subscriptionChange', (isSubscribed) => {
            if (isSubscribed) {
              this.$OneSignal.getUserId((userId) => {
                this.oneSignalUserId = userId
              })
            } else {
              this.oneSignalUserId = 'null'
            }
          })
        }
      })
    },
    createEventSubscription (): void {
      this.eventsController.unsubscribe()
      // New Events Subsctiption
      if (!this.user?.uuid) {
        return
      }

      return EventsUseCases.subscribe(
        this.eventsController,
        {
          userUUID: this.user.uuid
        },
        this.onEvent.bind(this)
      )
    },
    onEvent (response: EventSuscriptionGetter): void {
      if (!response.status) {
        EventBus.$emit('toast', 'error', 'On event error')
        return
      }

      const data: onUser = response?.data?.onUser
      const isCloseSession: boolean =
        data?.type === GraphqlEventTypes.CLOSE_SESSION || false
      const isDevice: boolean = data?.deviceToken === this.deviceToken || false

      if (isCloseSession && isDevice) {
        return this.closeSession(data.message)
      }

      if (data?.type === GraphqlEventTypes.TASK_NOTIFICATIONS) {
        return this.taskNotification(data.payload)
      }
    },
    closeSession (message: string): void {
      EventBus.$emit('toast', 'error', message)
      setTimeout(async () => {
        await AdviserStatusController.inactiveIfIsAdviser(this.isAgent)
        this.logout()
        this.$router.push(link.signin)
      }, 500)
    },
    taskNotification (payload: string): void {
      EventBus.$emit('toast', 'success', this.$t('newTaskReminder'))
      EventBus.$emit('new-task-reminder', payload)
    },
    refreshOnUpdate (event: any) {
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!event.detail || !event.detail.waiting) return
      // send message to SW to skip the waiting and activate the new SW
      event.detail.waiting.postMessage({ type: 'SKIP_WAITING' })
    },
    // Interval functions
    startInterval () {
      if (this.interval) {
        this.cancelInterval()
      }

      this.interval = setInterval(() => {
        this.idleTime++
      }, 60000)
    },
    cancelInterval () {
      window.clearInterval(this.interval)
    },
    mountEventToHandleIdle () {
      // events to clear idle time counter on
      const events = [
        'load',
        'mousemove',
        'mousedown',
        'click',
        'scroll',
        'keypress'
      ]

      for (const i in events) {
        window.addEventListener(events[i], () => {
          if (this.idleTime >= 5) {
            EventBus.$emit('IDLE:reset')
          }
          this.idleTime = 0
        })
      }
    },
    unmountEventToHandleIdle () {
      const events = [
        'load',
        'mousemove',
        'mousedown',
        'click',
        'scroll',
        'keypress'
      ]

      for (const i in events) {
        window.removeEventListener(events[i], () => {
          this.idleTime = 0
        })
      }
    },
    async setOnboardingStarted (): Promise<void> {
      await OnboardingUseCases.setOnboardingStarted(this.onboardingController, this.token)
      setTimeout(() => {
        this.getOnboardingStep()
      }, 500)
    }
  },
  watch: {
    loginQuery () {
      if (this.loginQuery) {
        this.setOnboardingStarted()
      }
    },
    async oneSignalUserId (newId) {
      if (!this.isLoggedIn) return

      if (newId) {
        this.setOneSignalUserId(newId)
        if (this.isLoggedIn) {
          const currentStatus = await this.getAdviserStatus(this.user.userId)
          await PushNotificationsController.setOneSignalStatus({
            active: currentStatus === UserStates.Online
          })
        }
      } else {
        if (this.oneSignalRetryAttempts < 3) {
          this.oneSignalRetryAttempts++
          setTimeout(() => {
            this.validateOneSignal()
          }, 1000)
        }
      }
    },
    selectedApp (newValue) {
      this.setSelectedPipeline(null)
      this.eventsController.unsubscribe()
      if (newValue?.uuid && this.isLoggedIn) {
        this.createEventSubscription()
        EventBus.$emit('closeDrawer--details')
        this.selectRoom(null)
        this.getUserReadonly()
        this.checkShowUserInfoCompletion()
      }
    },
    appUUID: {
      immediate: true,
      handler (newVal) {
        this.appSubscription.unsubscribe()
        if (newVal) {
          this.setAppSubscription()
        }
      }
    },
    selectedCompany: {
      immediate: true,
      deep: true,
      handler () {
        this.cleanTemporalData()
      }
    },
    changePassword (newVal) {
      const query = this.$route.query
      if (query?.wizard) {
        this.showChangePasswordDialog = false
      }
      if (this.$route.path !== link.onboarding) {
        this.showChangePasswordDialog = false
      }
      if (!this.user?.terms) {
        this.showChangePasswordDialog = false
      }
      this.showChangePasswordDialog = newVal
    },
    language (newVal) {
      loadLanguageAsync(newVal)
    },
    isLoggedIn (newVal, oldVal) {
      if (newVal) {
        if (newVal === true && oldVal === false) this.setAppSubscription()
        this.validateOneSignal()
      }
    }
  }
}
