/* eslint-disable camelcase */
import { PartnerTypEnum } from '@/components/models/App'
import { IPartnersController } from '../../domain/PartnersController'
import store from '@/store'
import { i18n } from '@/plugins/i18n/i18n'

export default class PartnersController implements IPartnersController {
  async getCommissions (data: any): Promise<any> {
    return ''
  }

  getPartnerUrl (type: string): string {
    const partnerType = store.getters['AppStore/getSelectedApp'].partnerType
    let baseUrl = ''
    const utm_source = partnerType
    let utm_medium
    const utm_campaign = partnerType
    const utm_term = store.getters['UserStore/getUser'].uuid
    const utm_content = store.getters['AppStore/getSelectedApp'].uuid
    const utm_partner = store.getters['AppStore/getSelectedApp']?.name?.replace(/ /g, '_')

    if (type === 'biky') {
      baseUrl = `${process.env.VUE_APP_BIKY_URL}/signup`
    } else {
      baseUrl = `${process.env.VUE_APP_BASE_KEYBE_URL}/signup`
    }

    if (partnerType === PartnerTypEnum.PARTNER_PRO) {
      utm_medium = store.getters['UserStore/getUser'].name
    } else if (partnerType === PartnerTypEnum.PARTNER_REFERRAL) {
      utm_medium = store.getters['AppStore/getSelectedApp']?.name?.replace(/ /g, '_') || ''
    } else if (partnerType === PartnerTypEnum.REFERRAL_OF_PARTNER) {
      utm_medium = store.getters['AppStore/getSelectedApp']?.partnerResponsible || ''
      if (!utm_medium) return i18n.t('noPartnerResponsible').toString()
    }
    console.log('==================UTMS==================')
    console.log('baseUrl', baseUrl)
    console.log('utm_source', utm_source)
    console.log('utm_medium', utm_medium)
    console.log('utm_campaign', utm_campaign)
    console.log('utm_term', utm_term)
    console.log('utm_content', utm_content)
    console.log('utm_partner', utm_partner)
    console.log('=======================================')

    return `${baseUrl}?utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}&utm_term=${utm_term}&utm_content=${utm_content}&utm_partner=${utm_partner}`
  }
}
