import { CurrencyEnum } from '@/components/configuration/Billing/CurrencyEnum'

export enum ArchiveTypeEnum {
  ARCHIVE = 'archive',
  UNARCHIVE = 'unarchive'
}

export interface ProductVariation {
  items?: string[];
  label?: string;
}

export interface ProductImage {
  orden: number;
  url: string;
}

export interface ProductPDF {
  description: string;
  file?: File | null;
  url: string;
}

export interface ProductCreate {
  appUUID: string,
  category: string[],
  product: string,
  checkColor: boolean,
  colors: string | any[],
  description: string,
  images: ProductImage[],
  videos?: ProductImage[],
  price: number,
  currency: CurrencyEnum,
  externalUrl: string,
  createdAt?: string,
  pdfs?: ProductPDF[]
}

export interface Product extends ProductCreate {
  id: string,
  sku: string,
  next: string,
  published: boolean,
  createdAt: string,
  option1?: ProductVariation,
  option2?: ProductVariation,
  option3?: ProductVariation,
}

export interface RelatedProducts {
  sku: string,
  product: string,
  price: number,
  currency: string,
  id: string
}

export interface ProductDiscount {
  displayNameTranslated?: string,
  longDescriptionTranslated?: string,
  key: string,
  displayName: string,
  longDescription: string,
  value: number,
    validationValue?: number
}
