




























import CatalogProductList from './CatalogProductList'
export default CatalogProductList
