































































import CatalogProduct from './CatalogProduct'
export default CatalogProduct
