import { IPartnersController, dataToGetCommissions, partnerBrandsGetter } from '../domain/PartnersController'

export default class PartnersUseCases {
  static async getCommissions (controller: IPartnersController, data: dataToGetCommissions) : Promise<partnerBrandsGetter> {
    return controller.getCommissions(data)
  }

  static getPartnerUrl (controller: IPartnersController, type: string): string {
    return controller.getPartnerUrl(type)
  }
}
