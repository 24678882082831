import { mapGetters, mapState } from 'vuex'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import KeybeText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KeybeSelect from '@/modules/DesignSystem/infrastructure/components/KeybeSelect/KeybeSelect/KeybeSelect.vue'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import { AdminTypes } from '@/components/configuration/administrators/enums/AdminTypes'
import SignInOnboardingTutorialBox from '@/modules/SignInOnboarding/infrastructure/components/SignInOnboardingTutorialBox/SignInOnboardingTutorialBox.vue'
import TeamUseCases from '../../../application/TeamUseCases'
import { dataToCreateTeamMember, teamMemberCreate } from '../../../domain/TeamController'
import TeamController from '../../controllers/Team.controller'
import EmitToast from '@/utils/EmitToast'
import { TeamMember } from '../../../domain/models/TeamMember'
import { ActivityLogModuleTypeEnum, ActivityActionTypeEnum, dataToWriteActivityLog } from '@/modules/Config/ActivityLogs/domain/ActivityLogsController'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import ActivityLogsUseCases from '@/modules/Config/ActivityLogs/application/ActivityLogsUseCases'
import ActivityLogsController from '@/modules/Config/ActivityLogs/infrastructure/controllers/ActivityLogs.controller'
import Colors from '@/utils/Colors'
import { debounce } from 'lodash'
export default {
  components: {
    KeybeButton,
    KeybeText,
    KeybeIcon,
    KeybeSelect,
    SignInOnboardingTutorialBox
  },
  computed: {
    ...mapGetters('UserStore', ['isSuperAdmin']),
    ...mapGetters('AppStore', ['isKeybeApp']),
    ...mapState('AppStore', ['language', 'selectedApp']),
    ...mapState('UserStore', ['token', 'user']),
    userTypes (): { text: string, value: string }[] {
      if (this.isSuperAdmin && this.isKeybeApp) {
        return [
          { text: this.$t(AdminTypes.AGENT), value: AdminTypes.AGENT.toString() },
          { text: this.$t(AdminTypes.COORDINATOR), value: AdminTypes.COORDINATOR.toString() },
          { text: this.$t(AdminTypes.ADMIN), value: AdminTypes.ADMIN.toString() },
          { text: this.$t(AdminTypes.SUPER_ADMIN), value: AdminTypes.SUPER_ADMIN.toString() }
        ]
      } else {
        return [
          { text: this.$t(AdminTypes.AGENT), value: AdminTypes.AGENT.toString() },
          { text: this.$t(AdminTypes.COORDINATOR), value: AdminTypes.COORDINATOR.toString() },
          { text: this.$t(AdminTypes.TRAINER), value: AdminTypes.TRAINER.toString() },
          { text: this.$t(AdminTypes.ADMIN), value: AdminTypes.ADMIN.toString() }
        ]
      }
    }
  },
  mounted () {
    if (this.invites.length === 0) {
      this.invites.push(
        { name: '', lastName: '', email: '', type: '', sent: false }
      )
    }
  },
  data () {
    return {
      Colors,
      CDPFieldsEnums,
      KeybeIconType,
      invites: [],
      showTutorial: true,
      boxPosition: 'margin-left: 390px',
      arrowPosition: 'bottom',
      headerText: this.$t('yourTeamYourRules'),
      subtitleText: this.$t('inviteTeamTutorial'),
      teamController: new TeamController(),
      activityLogsController: new ActivityLogsController()
    }
  },
  methods: {
    buttonPlaceholder (index: number): string {
      return this.invites[index].sent ? this.$t('sentInvite') : this.$t('sendInvite')
    },
    addInvite (): void {
      this.showTutorial = false
      this.invites.push({ name: '', lastName: '', email: '', type: '', sent: false })
    },
    sendInviteDebounce: debounce(function (index: number): void {
      this.sendInvite(index)
    }, 500),
    async sendInvite (index: number): Promise<void> {
      const data: dataToCreateTeamMember = {
        token: this.token,
        createInput: {
          name: this.invites[index].name,
          lastName: this.invites[index].lastName,
          type: this.invites[index].type,
          userType: this.invites[index].type,
          email: this.invites[index].email,
          password: 'Keybe2024',
          appUUID: this.selectedApp?.uuid,
          phone: '+',
          lang: this.selectedApp?.lang || this.language
        }
      }

      if (this.invites[index].type === AdminTypes.SUPER_ADMIN) {
        data.createInput.isSuperAdmin = 1
      }

      const response: teamMemberCreate = await TeamUseCases.createTeamMember(this.teamController, data)

      if (!response.status) EmitToast.emitErrors(response.message)
      else {
        this.invites[index].sent = true
        this.writeActivityLog(response.data)
      }
    },
    async writeActivityLog (member: TeamMember): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.TEAM,
          action: ActivityActionTypeEnum.TEAM_MEMBER_CREATE,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: CDPFieldsEnums.NAME,
            newValue: member.name,
            oldValue: ''
          }
        }
      }
      await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
    },
    closeTutorial (): void {
    }
  }
}
